#landing-container {
    width:100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column wrap;
    padding-bottom: 5em;
}

#landing-container .ant-descriptions-item-label {
    padding: 1em 2em;
}

#landing-container .ant-descriptions-item-content {
    padding: 1em 2em;
}

.page-row {
    width: 100%;
}
.landing-section {
    max-height: calc(100vh - 100px);
    overflow-y: scroll;
}

amplify-authenticator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.landing-row {
    width: 100%;
}

.landing-section {
    max-height: calc(100vh - 100px);
    overflow-y: scroll;
}

@media only screen and (max-width: 768px) {
    #landing-container .ant-row {
        flex-direction: column-reverse;
    }
    .login-container {
        position: relative;
        width: 100%;
    }
}

@media only screen and (min-width: 768px) {
    #landing-container {
        padding: 2em;
        flex-flow: row wrap;
        justify-content: space-between;
    }
    .login-container {
        position: fixed;
        width: 50%;
    }
}
.amplify-label {
    position: absolute!important;
    width: 1px!important;
    height: 1px!important;
    padding: 0!important;
    margin: -1px!important;
    overflow: hidden!important;
    clip: rect(0,0,0,0)!important;
    white-space: nowrap!important;
    border-width: 0!important;
}
.amplify-tabs {
    display: none !important;
}
h5.amplify-heading {
    color: var(--amplify-components-tabs-item-active-color);
}
    