#app-body {
  display: flex;
  height: 100%;
  flex-flow: column nowrap;
}

main {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  max-height: calc(100vh - 2em);
  height: 100%;
}

#main-section {
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 auto;

  width: 100%;
  box-sizing: border-box;
  padding: 0 2em;
  background-color: #FAFAFB;

  overflow:scroll;
}