header {
  padding: 0 2em;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  border-bottom: 1px solid #E6E6E6;
}

nav {
  display: flex;
  flex-flow: row nowrap;
}

#mobius-evo {
  margin: 0 2em 0 0;
  vertical-align: middle;
  line-height: 2em;
  cursor: pointer;
}

.nav-button { 
  height: 100%;
  cursor: pointer;
  z-index: 1;
  color: rgb(0,0,0,0.65);
  vertical-align: middle;
}

.nav-menu { 
  font-size: 16px;
  font-weight: 500;
}

.nav-active { 
  color: #1890ff;
  font-weight: 700;
}

.nav-settings-btn{
    border: 0px;
    margin-right: 2px;
}

#file-selector {
    display: none;
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
  }
  