.textArea {
    width: 50vw;
    /* height: 200px !important; */
}
.hiddenElement {
    display: none;
}
.error-row > td {
    background-color: #fff1f0 !important;
}
.error-row:hover > td {
    background-color: #ffccc7 !important;
}
.ant-alert {
    max-height: 200px;
    overflow-y: scroll;
}
.ant-alert::-webkit-scrollbar-track {
    background: #fff1f0; /* color of the tracking area */
}
.ant-alert::-webkit-scrollbar-thumb {
    background-color: #ffa39e; /* color of the scroll thumb */
    border: 2px solid #ffccc7; /* creates padding around scroll thumb */
}
.no-margin-form-item{
    margin: 0px;
}
p {
    margin: 7px 0px 7px 0px;
}
.grade-space {
    width: 100%;
}
.grade-select {
    width: 100%;
    /* width: 300px; */
}
.grade-select[disabled] {
    color: black;
    background-color: white;
    cursor: default;
}
.comment-textarea{
    width: 100%;

}
.comment-textarea[disabled]{
    color: black ;
    background-color: white ;
    cursor: default ;
}
.project-card {
    width: 450px;
}
.card-img {
    width: 400px;
    /* height: 300px; */
}
.mainSpace{
    width: 100%;
    height: calc(100vh - 45px);
    overflow-y: scroll;
}
.ant-spin-nested-loading{
    width: 100%;
}
.hiddenPrompt{
    display: none;
}